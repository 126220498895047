<template>
  <v-list>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-domain</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >{{ association.name }} ({{
            association.name_lowercase
          }})</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-account-group</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >Max: {{ association.max_members }}</v-list-item-title
        >
        Current: {{ association.users.length }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="association.mobile_signature_active">
      <v-list-item-icon>
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Mobile Signature</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="association.otp_signature_active">
      <v-list-item-icon>
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>OTP Signature</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="association.signer_association">
      <v-list-item-icon>
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Signer Association</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="association.footer_text">
      <v-list-item-icon>
        <v-icon>mdi-page-layout-footer</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="caption">{{
          association.footer_text
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "AssociationCard",
  props: { association: { type: Object, required: true } },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
