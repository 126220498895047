<template>
  <div v-if="association">
    <h3 class="text-h3 mb-8">
      Association: <tt>{{ association.name }}</tt>
    </h3>

    <v-tabs show-arrows>
      <v-tab class="justify-start">
        Details
      </v-tab>
      <v-tab class="justify-start">
        Pricing Plans
        <v-chip x-small dark color="grey" class="ml-2"
          >{{ association.pricing_plans.length }}
        </v-chip>
      </v-tab>
      <v-tab class="justify-start">
        Users
        <v-chip x-small dark color="grey" class="ml-2"
          >{{ association.users.length }}
        </v-chip>
      </v-tab>

      <v-tab-item>
        <AssociationCard :association="association" />
      </v-tab-item>
      <v-tab-item>
        <PricingPlanList
          v-if="association.pricing_plans.length > 0"
          :plans="association.pricing_plans"
        />
        <v-card flat v-else>
          <v-card-text>
            {{ association.name }} has no pricing plans assigned.
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p class="caption">
              {{ association.name }} has {{ association.users.length }} users.
            </p>
            <UserList :users="users" />
            <div v-if="users.length == 0 && association.users.length > 0">
              <v-btn class="mt-4" :loading="loading" @click="fetchUsers"
                >Load User Data</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Backend from "@/services/Backend";
import AssociationCard from "@/components/AssociationCard.vue";
import PricingPlanList from "@/components/PricingPlanList.vue";
import UserList from "@/components/UserList.vue";

const AUTOLOAD_THRESHOLD = 100;

export default {
  components: { AssociationCard, PricingPlanList, UserList },
  props: {
    id: { type: Number, required: true }
  },
  data() {
    return {
      loading: false,
      association: null,
      users: []
    };
  },
  mounted() {
    return this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.association = null;
      let response = await Backend.getAssociation(this.id);
      this.association = { pricing_plans: [], ...response.data };
      await this.fetchPricingPlans();
      this.loading = false;
      // preload users, if there are a few of them... else it takes too long
      if (this.association.users.length <= AUTOLOAD_THRESHOLD) {
        this.fetchUsers();
      }
    },
    async fetchUsers() {
      this.loading = true;
      let response = await Backend.getAssociationUsers(this.association);
      this.users = response.data.sort((x, y) =>
        x.username < y.username ? -1 : 1
      );
      this.loading = false;
    },
    async fetchPricingPlans() {
      this.loading = true;
      let response = await Backend.getAssociationPricingPlans(this.association);
      this.association.pricing_plans = response.data.sort((x, y) =>
        x.name < y.name ? -1 : 1
      );
      this.loading = false;
    }
  }
};
</script>
